import { useState, useEffect } from "react";
import axios from "axios";

const useGetAddressIO = () => {
  const [addressData, setAddressData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAddress = async (postcode) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://api.getAddress.io/find/${postcode}?api-key=Qqlyy4gbsEWmF_jeJcnQ1w45046&expand=true`
      );
      console.log(response, "response");
      setAddressData(response.data);
      setError(null);
    } catch (err) {
      setAddressData(null);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { addressData, error, loading, getAddress };
};

export default useGetAddressIO;
